<template>
  <div class="information page-container">
    <BannerSwiper height="280px" />
    <InformationFilter :initData="filterData" @change="getFilterData"/>
    <InformationListWrapper :nums="12" pagination :filterData="filterData" style="margin-top:26px" />
  </div>
</template>
<script>
import BannerSwiper from "components/bannerSwiper"
import InformationFilter from "components/information/informationFilter"
import InformationListWrapper from "components/information/informationListWrapper"
export default {
  name:"pageInformation",
  data:()=>({
    filterData:{
      curIndex:0,
      message_type: 1,
      search_content:''
    }
  }),
  created(){
    let filterData = {...this.filterData}
    filterData.curIndex = +this.$route.query.id
    filterData.message_type = ++this.$route.query.id||1
    filterData.search_content = this.$route.params.search_content||"" 
    this.filterData = filterData
  },
  methods:{
    getFilterData(data){
      this.filterData = data
    }
  },
  computed:{},
  watch:{},
  components:{
    BannerSwiper,
    InformationFilter,
    InformationListWrapper,
  }

}
</script>
<style lang="scss">
// @import "constants/style/public";
</style>