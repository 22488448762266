<template>
  <div class="information-list hasPagination">
    <div class="information-list-wrapper">
      <div v-for="(l, i) in data" :key="i" class="item" link="#">
        <InformationCard :data="l" :link="toInformationDetail+l.valley_message_id"/>
      </div>
    </div>
    <div class="pagination" v-if="nums&&pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="page"
        :total="total"
        :page-size="nums"
        :hide-on-single-page="!pagination||!total||total<nums"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import InformationCard from "components/informationCard";
import {toInformationDetail} from "constants/routerLink"
export default {
  name: "InformationListWrapper",
  props: {
    nums: Number,
    pagination: Boolean,
    filterData: {
      type:[Object],
      // default(){
      //   return{
      //     total:null
      //   }
      // }
    },
  },
  data: () => ({
    page: 1,
    total: null,
    data: null,
  }),
  mounted() {
    if(this.filterData){
      this.getData();
    }
  },
  methods: {
    handleCurrentChange(page){
      this.page = page
      this.getData()
    },
    getData() {
      let params = {
          limit: this.nums,
          offset: (this.page-1)*this.nums,
        }

      let data = {...this.filterData}
      if (Object.values(this.filterData).length) {
        delete data.curIndex
        params = Object.assign(params,data);
      }
      // console.log(params);
      this.$axiosRequest({
        name: "getValleyMessage",
        params: params
      })
        .then((res) => {
          // console.log(res.data);
          if (res.status === 200) {
            this.data = res.data.results;
            this.total = res.data.count
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    toInformationDetail:()=>toInformationDetail
  },
  watch: {
    filterData(val,prev){
      if(val!==prev){
        this.page = 1
        this.getData();
      }
    }
  },
  components: {
    InformationCard,
  },
};
</script>
<style lang="scss">
@import "constants/style/public";
.information-list{
  .information-list-wrapper {
    padding: 0 20px;
    overflow: hidden;
    border-radius: $borderRadius;
    background-color: $headBgColor;
    // display: flex;
    // flex-wrap: wrap;
    .item {
      // margin-right: 36px;
      // margin-bottom: 36px;
      // &:nth-of-type(3n) {
      //   margin-right: 0;
      // }
    } 
  }
  .pagination {
      margin: 30px auto 40px;
    }
}

</style>