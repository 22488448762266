<template>
<div>
  <a target="_blank" :href="data.outside_link||link" class="information-card toInformationDetail">
    <el-row class="row">
      <el-col :span="13" >
       <div class="title ">
          <div v-if='data.hot || isNew' class="hot-and-new">
            <span class="txt" v-if='data.hot'>
              <el-image
                class="hot"
                :src="hotImg"
                fit="cover"></el-image>
            </span>
            <span class="txt" v-else>
              <el-image
                class="new"
                :src="newImg"
                fit="cover"></el-image>
            </span>
          </div> 
          <div :class="data.message_mode===2&&'text-underline'" class="title-text ellipsis">
            {{data.message_title||'文章标题'}}&nbsp;&nbsp;
          </div>
        </div>
      </el-col>

      <el-col :span="3" class="name">
        {{mode[data.message_mode]||'资讯模式'}}
      </el-col>
      <el-col :span="6" class="name ellipsis">
        {{data.source_from||data.valley_name||'机构名称'}}
      </el-col>
      <el-col :span="2" class="time">{{processDate(data.published_at)||'2021/08/18'}}</el-col>
    </el-row>
  </a>
  
  <!-- <div v-else class="information-card">
    <div class="title ellipsis">{{data.message_title||'文章标题'}}</div>
    <div class="right">
      <div class="name">{{data.valley_name||'机构名称'}}</div>
      <div class="time">{{updateTime||'2021/08/18'}}</div>
    </div>
  </div> -->
 
</div>
  
  
</template>
<script>
export default {
  name: "InformationCard",
  props: ["data","link"],
  data: () => ({}),
  methods: {
    processDate(date,format){
      return this.$dayjs(date).format(format||'YYYY/MM/DD')
    }
  },
  computed: {
    hotImg:()=>require("assets/information_hot.png"),
    newImg:()=>require("assets/information_new.png"),
    isNew(){
      if(this.data){
        return !!(!this.data.hot &&( this.$dayjs().diff(this.$dayjs(this.data.updated_at),'day') <=7 ))
      }else return false
    },
    mode:()=>["","文章资讯","外部跳转"]
  },
  watch: {},
};
</script>
<style lang="scss">
@import "constants/style/public";
.information-card {
  display: flex;
  padding: 20px 0;
  justify-content: space-between;
  color: $promptColor;
  font-size: $medium;
  text-align: left;
  background-color: $defaultBg;
  border-bottom: 1px solid $lineColor;

  &:hover{
    .title{
      color: var(--moreColor,#666);
    }
    .text-underline{
      &::after{
        background-color: var(--moreColor,#666);
      }
    }
  }
  .row{
    width: 100%;
  }
  .title {
    max-width: 560px; 
    display: flex;
    color: $titleColor;
    align-items: center;
    .title-text{
      max-width: 530px; 
    }
  }
  .text-underline{
    &::after{
      content: " ";
      display: block;
      position: relative;
      top: 0px;
      height: 1px;
      background-color: var(--promptColor,#666);
    }
  }
  .new-style{
    position:absolute;
    top: 1px;
    right: 2px;
    width: 8px;
    height: 8px;
    background: radial-gradient(circle,red 35%,transparent);
    border-radius: 50%;
  }
  .hot-and-new{
    width: 14px;
    height: 14px;
    display: inline-block;
    margin-right: 8px;
    text-align: center;
    .hot,.new{
      width: 100%; 
      height: 100%;
    }
  }
  &.toInformationDetail{
    cursor: pointer;
  }
  
  .right {
    display: flex;
    align-items: center;
    .name {
      margin-right: 100px;
    }
  }
}
</style>