<template>
  <div class="information-filter-wrapper">
      <div class="information-filter-card">
        <div class="information-filter-item top">
          <div class="information-filter-item-left">
            <div
              :class="'filter-job-cate ' + (curIndex === i ? 'active' : '')"
              v-for="(l, i) in selectKeys"
              :key="i"
              @click="toggleCate(i)"
              v-text="l.label"
            />
          </div>
          <div class="search-box">
            <el-input
              placeholder="请输入内容"
              class="search-input"
              prefix-icon="el-icon-search"
              @change="searched"
              v-model="search_content"
              size="small"
            >
            </el-input>
          </div>
        </div>

        <!-- <div class="jobs-filter-item">
          <div class="jobs-filter-item-left">
            <el-dropdown
              trigger="click"
              class="dropdown-box"
              @command="handleCommand($event, 'experiences')"
            >
              <span class="el-dropdown-link">
                工作经验：<span class="drop-title">
                  {{ experiences || "不限" }}
                </span>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="l in experienceList"
                  :command="l.value"
                  :key="l.label"
                  v-text="l.label"
                />
              </el-dropdown-menu>
            </el-dropdown>

            <el-dropdown
              trigger="click"
              class="dropdown-box"
              @command="handleCommand($event, 'educations')"
            >
              <span class="el-dropdown-link">
                学历要求：<span class="drop-title">
                  {{ educations || "不限" }}
                </span>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="l in educationList"
                  :command="l.value"
                  :key="l.label"
                  v-text="l.label"
                />
              </el-dropdown-menu>
            </el-dropdown>

            <el-dropdown
              trigger="click"
              class="dropdown-box"
              @command="handleCommand($event, 'salary_range')"
            >
              <span class="el-dropdown-link">
                薪资要求：<span class="drop-title">
                  {{ salary_range || "不限" }}
                </span>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="l in salaryList"
                  :command="l.value"
                  :key="l.label"
                  v-text="l.label"
                />
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <div class="filter-clear" @click="clear">清空筛选条件</div>
        </div> -->
      </div>
    </div>
</template>
<script>
import {  informationCate } from "constants/publicData";
export default {
  name:"ActivityFilter",
  props:{
    initData:Object,
  },
  data(){
    return {
      curIndex: 0,
      // experiences: "",
      // educations: "",
      // salary_range: "",
      // industry: "",
      search_content: "",
      message_type:1,
      selectItmes:{},
      back:{}
    } 
  },
  created(){
    if(this.initData){
      this.init(this.initData)
    }
  },
  methods: {
    init(initData){
      console.log(initData);
      const {curIndex,search_content} = initData
      // const {curIndex,experiences,educations,salary_range,search_content} = initData

      if(curIndex!==0&&curIndex!==undefined&&curIndex!==null&&curIndex!==""&&!isNaN(curIndex)){
        if(typeof curIndex=='number') this.curIndex = curIndex||0
        else console.error('initData.curIndex error'); 
      }
      // if(experiences){
      //   if(experience.some(item=>item===experience)) this.experiences = experiences||""
      //   else console.error('initData.experience error');
      // }

      // if(educations){
      //   if(education.some(item=>item===educations)) this.educations = educations||""
      //   else console.error('initData.education error');
      // }

      // if(salary_range){
      //   if(salary.some(item=>item===salary_range)) this.salary_range = salary_range||""
      //   else console.error('initData.curIndex error');
      // }
      // informationCate.map((item) => {
      //   if (initData[item.value]) {
      //     this.selectItmes[item.value] = initData[item.value];
      //   }
      // });

      if(search_content){
        this.search_content = search_content||""
      }
      this.callBack()
    },
    toggleCate(i) {
      this.curIndex = i;
      
      // if(Object.keys(this.selectItmes).length){
      //   for(let key in this.selectItmes){
      //     this.selectItmes[key]=""
      //   }
      // }
      
      // if(informationCate[i]!==-1&&informationCate[i].value){
      //   this.selectItmes[informationCate[i].value]=true
      // }else if(informationCate[i].value){
      //   this.selectItmes[informationCate[i].value]=""
      // }
      this.callBack()
    },
    // handleCommand(command, name) {
    //   this[name] = command;
    //   this.callBack()
    // },
    searched(val) {
      this.search_content = val
      this.callBack()
    },
    // clear() {
    //   this.experiences = "";
    //   this.educations = "";
    //   this.salary_range = "";
    //   this.callBack()
    // },
    callBack(){
      let data = {
        curIndex :this.curIndex,
        // experiences:this.experiences,
        // educations:this.educations,
        // salary_range:this.salary_range,
        search_content:this.search_content,
        // industry:this.industry,
        message_type:this.curIndex+1
        // ...this.selectItmes
      }
      console.log(data);
      this.$emit('change',data)
    }
  },
  computed: {
    selectKeys:()=>informationCate,
    // experienceList: () => experience,
    // salaryList: () => salary,
    // educationList: () => education,
  },
  watch: {},

}
</script>
<style lang="scss">
@import "constants/style/public";
.information-filter-card {
    background-color: $defaultBg;
    margin-top: 26px;
    padding: 15px 10px;
    box-shadow: 0 0 18px $shadowColor;
    border-radius: $borderRadius;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .information-filter-item {
    padding: 20px 13px 0;
    display: flex;
    justify-content: space-between;
    &.top {
      padding: 0;
      // padding-bottom: 15px;
      // border-bottom: 1px solid $lineColor;
    }
    .information-filter-item-left {
      display: flex;
      .dropdown-box {
        margin-right: 140px;
        .drop-title {
          color: $moreColor;
          margin-right: 20px;
        }
        .el-dropdown-link {
          cursor: pointer;
        }
      }
      .filter-job-cate {
        position: relative;
        cursor: pointer;
        margin: 0 13px;
        padding: 7px 23px;
        &.active {
          &::after{
            width: 100%;
            height: 2px;
            content: "";
            position: relative;
            top: 12px;
            background-color: var(--moreColor,#666);
            display: block;
          }
        }
      }
    }
    .search-input {
      .el-input__inner {
        border-radius: $borderRound;
        &:focus{
          border-color: $moreColor;
        }
        // background-color: $searchBg;
      }
    }
    .filter-clear {
      cursor: pointer;
      font-size: $medium;
      color: $moreColor;
    }
  }
</style>